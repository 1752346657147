// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-residency-tsx": () => import("./../../../src/components/templates/residency.tsx" /* webpackChunkName: "component---src-components-templates-residency-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-new-residencies-tsx": () => import("./../../../src/pages/new-residencies.tsx" /* webpackChunkName: "component---src-pages-new-residencies-tsx" */),
  "component---src-pages-residencies-tsx": () => import("./../../../src/pages/residencies.tsx" /* webpackChunkName: "component---src-pages-residencies-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */)
}

