import React from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'


{/* const imgixPath = (input: string) => input.replace('cdn.sanity.io/files/zchnowat/production', 'franklyn-files.imgix.net') */}
const imgixPath = (input: string) => input


const Video = ({autoPlay, muted, loop, hideIfMobile, hideIfDesktop, playsInline, src}: VideoProps): React.ReactElement => {
  if(!src) return <></>

  const { ref, inView } = useInView({
    threshold: .15,
    triggerOnce: true
  })
  
  const [visible, setVisible] = React.useState(false)
  React.useEffect(() => {
    if(visible) return
    if(inView) setVisible(true)
  }, [inView])

  return (
    <VideoTag {...{ autoPlay, muted, loop, hideIfMobile, hideIfDesktop, playsInline, visible, ref }}>
      <source src={imgixPath(src)} />
    </VideoTag>
  )
}



interface VideoTagProps { 
  rotation?: number
  hideIfDesktop?: boolean
  hideIfMobile?: boolean
  visible: boolean
}

const VideoTag = styled.video.attrs(
  ({ visible }: VideoTagProps) => {
  return { style: {
    opacity: visible ? 1 : 0,
  } }}
)<VideoTagProps>`
  background-color: black;
  width: 100%;
  max-width: 100%;
  @media only screen and (max-width: 767px) {
    ${props => props.hideIfMobile && `display: none;`}
  } 
  @media only screen and (min-width: 768px) {
    ${props => props.hideIfDesktop && `display: none;`}
  }
  transition: opacity 0.5s ease-in-out, border-radius 0.25s ease-in-out;
  @media only screen and (min-width: 768px) {
    transition: opacity 0.5s ease-in-out, border-radius 0.25s ease-in-out, transform 0.25s ease-out;
  }
  @media only screen and (max-width: 767px) {
    transform: none !important;
  }
`


type VideoProps = {
  autoPlay?: boolean
  muted?: boolean
  loop?: boolean
  hideIfMobile?: boolean
  hideIfDesktop?: boolean
  playsInline?: boolean
  src: string
}


export default Video