import React from "react"
import styled from 'styled-components'
import { Link, Navigation, Newsletter, } from '@components'
import { Grid } from '@components/grid'
import { useLocation } from '@reach/router'
import { GridHelperSwitch } from '@utils'


const Header = () => {
  const { pathname } = useLocation()
  const goHome = () => {
    if( pathname === '/' ) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }
  return (
    <>
      <Sitename to="/" onClick={() => goHome()} >
        <Logo src="/LandBase.svg" alt="Land Base" />
      </Sitename>
      <Email href="mailto:ola@landbase.pt">ola@landbase.pt</Email>
      <Navigation />
      <StyledGrid>
        <GridColumnWidth id="gridColumnWidth" />
      </StyledGrid>
      <Newsletter />
      <StyledGridHelperSwitch />
    </>
  )
}


const StyledGridHelperSwitch = styled(props => <GridHelperSwitch {...props} />)`
  position: fixed;
  z-index: 300;
  bottom: 5px;
  right: 5px;
  color: white !important;
`

const StyledGrid = styled(props => <Grid {...props} />)`
  grid-row-gap: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
`

const GridColumnWidth = styled.div`
  grid-column-end: span 1;
  @media only screen and (min-width: 768px) {
    grid-column-end: span 1;
  }
`

const Sitename = styled(props => <Link {...props} />)`
  position: fixed;
  z-index: 120;
  @media only screen and (min-width: 768px) {
    z-index: 100;
  }
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 36px 0;
  backdrop-filter:  blur(15px);
  background-color: rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
`

const Logo = styled.img`
  width: 228px;
  height: auto;
`

const Email = styled.a`
  display: none;
  @media only screen and (min-width: 768px) {
    display: block;
    position: fixed;
    z-index: 101;
    left: 36px;
    bottom: 80px;
    transform-origin: top left;
    transform: rotateZ(-90deg);
    font-size: 18px;
    font-weight: 500;
    color: white;
    text-decoration: none;
  }
`

export default Header