export const palette = {
  calico: 'rgb(226, 197, 141)',
  driftwood: 'rgb(178, 130, 74)',
  palliser: 'rgb(167, 115, 60)',
}

export const effect = {
  magnify: `
    @media only screen and (min-width: 920px) {
      transition: transform 0.25s ease-in-out;
      transform-origin: center center;
      transform: scale(1.001);
      &:hover {
        transform: scale(1.025);
      }  
    }
  `,
}