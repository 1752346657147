import React from "react"
import styled from 'styled-components'
import { Link } from '@components'


interface LinkMaskProps {
  link?: string
}


const LinkMask = ({ link }: LinkMaskProps) => {
  if(link) {
    return <AnchorWrapper href={link} />
  }
  return <></>
}


const LinkMaskStyle = `
  display: block;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  & ~ div .linkHighlight {
    transition: color 0.25s ease-in-out;    
  }
  &:hover ~ div .linkHighlight {
    color: #e2c58d;
  }
`

const AnchorWrapper = styled.a`
  ${LinkMaskStyle}
`


const LinkWrapper = styled(props => <Link {...props} />)`
  ${LinkMaskStyle}
  &:hover ~ div .linkHighlight {
    color: #e2c58d;
  }
`


export default LinkMask