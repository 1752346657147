import React from "react"
import styled from 'styled-components'
import { BlockRenderer } from '@components'
import { GridItemCaptionProps } from '@types'
import { GridItemWrapper } from '@components/grid'
import { useLanguage, useGridHelper } from '@utils'


const GridItemCaption = ({
  link,
  anchor,
  children,
  gridItemTypeSwitch,
  className,
  columns, 
  columnStart,
}: GridItemCaptionProps & { className?: string }): React.ReactElement => {
  const { language } = useLanguage()
  const { gridHelperEnabled } = useGridHelper()
  if( !gridItemTypeSwitch.gridItemCaption ) return <></>  
  const {
    gridItemCaption: {
      _rawCaption,
      _rawCaptionRu,
      _rawCaptionPt,
      topLine,
    },
  } = gridItemTypeSwitch
  return (
    <GridItemWrapper {...{ className, columns, columnStart, link, anchor, topLine, gridHelperEnabled }} >
      <ItemCaption className="switchable" >
        {language === 'en' && <BlockRenderer>{_rawCaption}</BlockRenderer>}
        {language === 'ru' && <BlockRenderer>{_rawCaptionRu}</BlockRenderer>}
        {language === 'pt' && <BlockRenderer>{_rawCaptionPt}</BlockRenderer>}
      </ItemCaption>
      {children}
    </GridItemWrapper>
  )
}




export const ItemCaption = styled.div`
  position: relative;
`


export default GridItemCaption