import React from "react"
import styled from 'styled-components'

const PageTitle = styled.h1`
  grid-column-end: span 4;
  font-size: 2em;
  font-weight: bold;
  @media only screen and (min-width: 768px) {
    grid-column-end: span 6;
  }
`

export default PageTitle