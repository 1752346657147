import React from 'react'
import styled from 'styled-components'
import { useLocation } from '@reach/router'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

const Newsletter = () => {
  const { pathname } = useLocation()
  const [visible, setVisible] = React.useState(false)
  const [sent, setSent] = React.useState(false)
  
  const dismissForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    setSent(true)
    setVisible(false)
  }

  useScrollPosition(
    ({ currPos }) => {
      if(!visible && !sent && (currPos.y > 500) && pathname === '/') {
        setVisible(true)
      }
    }, // effect callback
    [visible, sent], // dependencies
    undefined, // position of element
    true, // use window instead of body.getBoundingClientRect
    10, // performance debounce
  )
  // Подписывайтесь на нашу новостную рассылку

  return (
    <Wrapper {...{ visible }}>
      <Form
        {...{ sent }}
        method="POST"
        action="https://515e6c6a.sibforms.com/serve/MUIEABtt2hOB29s_MbW10r3q8yET2fL-4ZQYdH8hO3ywIGgaXAHLuBkQ0uRbK6Nvp5IdI7Yb3AQKc9ZsyxU50uMozFavFtPk89xAGi4-4Z1my-SgY5pJlNGDWXv1P-Kze4GPIMlNFENrOfNq5aFebdxwBvBrPOvU86aPDO6iKkjSRLtLhsqIskd9QnDQJU82ij_ITe1IbYA3ZVuA"
      >
        <Input type="email" name="EMAIL" placeholder="Subscribe to our newsletter?" required />
        <input type="hidden" value="1" name="OPT_IN" />
        <InputSubmit type="submit" value="Send" />
        <Cancel onClick={e => dismissForm(e)} >No thanks</Cancel>
      </Form>
      <Sent {...{ sent }} />
    </Wrapper>
  )
}



const Wrapper = styled.div<{ visible: boolean }>`
  position: fixed;
  z-index: 101;
  right: 0px;
  bottom: 100px;
  width: 100%;
  @media only screen and (min-width: 768px) {
    bottom: 0px;
    width: 460px;
  }
  @media only screen and (max-width: 767px) {
    ${props => props.visible && `
      backdrop-filter:  blur(15px);
      background-color: rgba(0, 0, 0, 0.25);
    `}
  }
  touch-events: all;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s 0.1s ease-in-out, visibility 0.01s 0.35s linear;
  ${props => props.visible && `
    visibility: visible;
    touch-events: all;
    transform: scale(1);
    opacity: 1;
    transition: 
      visibility 0.01s 0s linear, 
      opacity 0.3s 0.1s ease-in-out, 
      transform 0.3s 0.1s ease-in-out;
  `}

`

const Form = styled.form<{ sent: boolean }>`
  position: relative;
  display: grid;
  grid-columns: repeat(5, 1fr);
  grid-rows: 2;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`

const Input = styled.input`
  grid-column-end: span 5;
  -webkit-display: none;
  display: block;
  border-bottom: 1px solid black;
  border-radius: 0;
  font-family: 'Graphik', 'Verdana', sans-serif;
  font-size: 20px;
  font-weight: 500;
  background-color: #b2824a;
  transition: background-color 0.15s ease-in-out;
  &:hover {
    background-color: #e2c58d;
  }
  color: black;
  
  padding: 26px;
  @media only screen and (min-width: 768px) {
    font-size: 20px;
    padding: 40px;
  }
  @media only screen and (min-width: 1280px) {
    font-size: 27px;
  }
  box-sizing: border-box;
  &::placeholder {
    color: black;
    opacity: 0.5;
  }
`



const InputSubmit = styled.input`
  grid-column-end: span 4;
  -webkit-display: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  background-color: #b2824a;
  transition: background-color 0.15s ease-in-out;
  &:hover {
    background-color: #e2c58d;
  }
  border-radius: 0;
  background-image: url('/yes.svg');
  background-size: auto 50px;
  background-repeat: no-repeat;
  background-position: center 60%;
  color: black;
  font-size: 10px;
  text-indent: -1000px;
  overflow: hidden;
  cursor: pointer;
`

const Cancel = styled.button`
  position: relative;
  grid-column-end: span 1;
  -webkit-display: none;
  display: flex;
  align-items: center;
  justify-content: center;  
  background-color: #b2824a;
  transition: background-color 0.15s ease-in-out;
  &:hover {
    background-color: #e2c58d;
  }
  background-size: 50% auto;
  background-repeat: no-repeat;
  background-position: center center;
  color: transparent;
  font-size: 10px;
  cursor: pointer;
  &:after {
    content: ' ';
    width: 55px;
    height: 1px;
    left: calc(50% - 20px);
    top: 20px;
    transform-origin: top left;
    position: absolute;
    transform: rotateZ(45deg);
    background: black;
  }
  &:before {
    content: ' ';
    width: 55px;
    height: 1px;
    left: calc(50% - 20px);
    bottom: 20px;
    transform-origin: top left;
    position: absolute;
    transform: rotateZ(-45deg);
    background: black;
  }
  @media only screen and (max-width: 767px) {
    &:after {
      width: 40px;
      top: 25px;
      left: calc(50% - 16px);
    }
    &:before {
      width: 40px;
      bottom: 26px;
      left: calc(50% - 16px);
    }
  }
`


const Sent = styled.div<{ sent: boolean }>`
  background-color: #b2824a;
  background-image: url('/thankyou.svg');
  background-repeat: no-repeat;
  background-size: 300px auto;
  background-position: center center;
  pointer-events: ${props => props.sent ? 'all' : 'none'};
  opacity: ${props => props.sent ? '1' : '0'};
  transition: opacity 0.3s ease-in-out;
`


export default Newsletter

/*


<!-- START - We recommend to place the below code in footer or bottom of your website html  -->
<script>
  window.REQUIRED_CODE_ERROR_MESSAGE = 'Please choose a country code';
  window.LOCALE = 'en';
  window.EMAIL_INVALID_MESSAGE = window.SMS_INVALID_MESSAGE = "The information provided is invalid. Please review the field format and try again.";

  window.REQUIRED_ERROR_MESSAGE = "This field cannot be left blank. ";

  window.GENERIC_INVALID_MESSAGE = "The information provided is invalid. Please review the field format and try again.";




  window.translation = {
    common: {
      selectedList: '{quantity} list selected',
      selectedLists: '{quantity} lists selected'
    }
  };

  var AUTOHIDE = Boolean(0);
</script>
<script src="https://sibforms.com/forms/end-form/build/main.js"></script>


<!-- END - We recommend to place the above code in footer or bottom of your website html  -->
<!-- End Sendinblue Form -->
*/