import React from 'react'
import styled from 'styled-components'
import { H2 } from '@components/BlockRenderer'
import { Anchor } from '@components'
import { palette } from '@theme'

const Contact = () => {
  const [sent, setSent] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [name, setName] = React.useState('')
  const submit = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if(!email || email === '' || !name || name === '') {
      e.preventDefault();
      return
    }
    // submits itself; then
    setSent(true)
    if(subscribe) {
      let formBody = `EMAIL=${encodeURIComponent(email)}&OPT_IN=1`
      fetch('https://515e6c6a.sibforms.com/serve/MUIEABtt2hOB29s_MbW10r3q8yET2fL-4ZQYdH8hO3ywIGgaXAHLuBkQ0uRbK6Nvp5IdI7Yb3AQKc9ZsyxU50uMozFavFtPk89xAGi4-4Z1my-SgY5pJlNGDWXv1P-Kze4GPIMlNFENrOfNq5aFebdxwBvBrPOvU86aPDO6iKkjSRLtLhsqIskd9QnDQJU82ij_ITe1IbYA3ZVuA', {
        method: 'POST',
        body: formBody,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });        
    }
  }
  const [subscribe, setSubscribe] = React.useState(false)
  return (
    <>
      <StyledH2>
        Contact
        <Anchor id="contact" />
      </StyledH2>
      <FormWrapper>
        <MailForm {...{ sent }} action="https://getform.io/f/86762f65-56bb-4946-ab1d-1aa526df7364" method="POST" >
          <Inputs>
            <Input type="text" placeholder="Name" name="name" value={name} onChange={e => setName(e.target.value)} required />
            <Input type="email" placeholder="Email" name="email" required value={email} onChange={e => setEmail(e.target.value)}/>
            <TextareaWrapper>
              <Textarea rows={2} placeholder="Message" name="message" required />
            </TextareaWrapper>
            <Label>
              <div>
                <InputCheckbox type="checkbox" defaultChecked={subscribe} onClick={() => setSubscribe(!subscribe)} />
              </div>
              <div>Subscribe to our newsletter</div>
            </Label>
          </Inputs>
          <InputSubmit type="submit" value="Send" onClick={e => submit(e)} />
        </MailForm>
        <Sent {...{ sent }} />
      </FormWrapper>
    </>
  )
}


const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media only screen and (min-width: 768px) {
    grid-column-end: span 2;
  }
  font-family: 'Graphik', 'Verdana', sans-serif;
  font-size: 18px;
  font-weight: 500;
  @media only screen and (min-width: 768px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 1280px) {
    font-size: 28px;
  }
  cursor: pointer;
  padding: 40px 0px;
`


const InputCheckbox = styled.input`
  appearance: none;
  position: relative;
  width: 40px;
  height: 40px;
  margin-right: 15px;
  background-color: ${palette.palliser};
  transition: background-color 0.15s ease-in-out;
  &:hover {
    background-color: ${palette.driftwood};
  }
  border-radius: 9999px;
  border: 1px solid black;
  cursor: pointer;
  &:checked {
    background-color: ${palette.calico};
  }
  &:checked:after {
    content: ' ';
    position: absolute;
    top: 26px;
    left: 8px;
    width: 10px;
    height: 1px;
    background: black;
    transform-origin: top right;
    transform: rotateZ(45deg);
  }
  &:checked:before {
    content: ' ';
    position: absolute;
    top: 26px;
    left: -2px;
    width: 20px;
    height: 1px;
    background: black;
    transform-origin: top right;
    transform: rotateZ(135deg);
  }
  @media only screen and (min-width: 768px) {
    width: 86px;
    height: 86px;
    margin-right: 30px;
    &:checked:after {
      top: 60px;
      left: 15px;
      width: 25px;
      height: 1px;
    }
    &:checked:before {
      top: 60px;
      left: 0px;
      width: 40px;
      height: 1px;
    }
  }
`



const FormWrapper = styled.div`
  position: relative;
  grid-column-start: 1;
  grid-column-end: span 4;
  @media only screen and (min-width: 768px) {
    grid-column-end: span 6;
  }
`

const StyledH2 = styled(props => <H2 {...props} />)`
  position: relative;
  grid-column-start: 1;
  grid-column-end: span 4;
  @media only screen and (min-width: 768px) {
    grid-column-end: span 6;
  }
`

const Inputs = styled.div`
  display: contents;
  @media only screen and (max-width: 1023px) {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-rows: repeat(5, auto);
    padding: 20px;
    background-color: ${palette.palliser};
  }
`

const MailForm = styled.form<{ sent: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: repeat(5, auto);
  @media only screen and (min-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(3, auto);
    padding: 0px 55px 0px 55px;
    margin-bottom: 350px;
  }
  background-color: ${palette.palliser};
  color: black;
  pointer-events: ${props => props.sent ? 'none' : 'all'};
  opacity: ${props => props.sent ? '0' : '1'};
  transition: opacity 0.15s ease-in-out;
`

const InputStyle = `
  -webkit-display: none;
  display: block;
  border-bottom: 1px solid black;
  border-radius: 0;
  font-family: 'Graphik', 'Verdana', sans-serif;
  font-size: 20px;
  font-weight: 500;
  @media only screen and (min-width: 768px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 1280px) {
    font-size: 28px;
  }
  box-sizing: border-box;
  color: white;
`

const Input = styled.input`
  grid-column-end: span 1;
  ${InputStyle}
  margin-bottom: 0.5em;
  padding-top: 35px;
  height: 85px;
  &::placeholder
    color: black;
    opacity: 1;
  }
`

const TextareaWrapper = styled.div`
  position: relative;
  @media only screen and (min-width: 768px) {
    grid-column-end: span 2;
  }
`

const Textarea = styled.textarea`
  ${InputStyle}
  width: 100%;
  height: 190px;
  padding-top: 20px;
  line-height: 1.4em;
`


const InputSubmit = styled.input`
  -webkit-display: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 100%;
  border-top: 8px solid black;
  @media only screen and (min-width: 1024px) {
    width: 100%;
    position: absolute;
    z-index: 2;
    bottom: 0px;
    right: 0;
    width: 245px;
    border-left: 8px solid black;
  }
  
  transition: background-color 0.15s ease-in-out;
  background-color: #a7733c;
  &:hover {
    background-color: #844F18;
  }
  background-image: url('/send.svg');
  background-size: 120px auto;
  background-repeat: no-repeat;
  background-position: center center;
  color: black;
  border-radius: 0;
  font-size: 10px;
  text-indent: -1000px;
  overflow: hidden;
  cursor: pointer;
`


const Sent = styled.div<{ sent: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: repeat(5, auto);
  padding: 20px;
  @media only screen and (min-width: 768px) {
    bottom: auto;
    top: 0;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(3, auto);
    padding: 0px 55px 0px 55px;
    margin-bottom: 350px;
  }
  background-color: #a7733c;
  color: black;
  background-image: url('/thankyou.svg');
  background-repeat: no-repeat;
  background-size: 85% auto;
  @media only screen and (min-width: 768px) {
    background-size: 300px auto;
  }
  background-position: center center;
  pointer-events: none;
  opacity: ${props => props.sent ? '1' : '0'};
  transition: opacity 0.3s ease-in-out;
`


export default Contact