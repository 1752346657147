import React from "react"
import styled from 'styled-components'
import BlockContent from '@sanity/block-content-to-react'


const JournalBlockSerializer = ({children, node}: { children: React.ReactNode, node: { style: string } }): JSX.Element => {
  if(!children) return <></>
  switch(node.style) {
    case 'h1' :
      return <H1>{children}</H1>
    case 'h2' :
      return <H2>{children}</H2>
    case 'blockquote' :
      return <Blockquote>{children}</Blockquote>
    default:
      return <Paragraph>{children}</Paragraph>
  }
}

const JournalBlockRenderer = ({ children }: { children: React.ReactNode }) => {
  return (
    <StyledBlockContent
      blocks={children}
      serializers={{
        types: {
          block: (props: any) => (<JournalBlockSerializer {...props} />),
        },
        marks: {
          footnote: (props: any) => (<Footnote {...props} />),
        },
      }}
    />
  )
}



const StyledBlockContent = styled(props => <BlockContent {...props} />)`
    > p {
      margin-bottom: 1em;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    ol {
      font-family: 'Exchange Text', serif;
      font-size: 20px;
      list-style-type: decimal;
      margin-left: 100px;
      li {
        list-style: decimal;
      }
    }
`

const Blockquote = styled.blockquote`
  display: block;
  width: 60%;
  float: right;
  font-family: 'Graphik', 'Verdana', sans-serif;
  line-height: 1.6em;
  font-size: 24px;
  font-weight: 400;
  padding-bottom: 1em;
  transform: translateX(50px);
`

const Footnote = styled.span`
  display: block;
  position: absolute;
  margin-top: -2em;
  right: 10px;
  font-family: 'Graphik', 'Verdana', sans-serif;
  font-size: 14px;
  line-height: 1.6em;
  width: 80px;
`

const H1 = styled.h1`
  font-family: 'Exchange Text', serif;
  line-height: 1.5em;
  font-size: 24px;
`

const H2 = styled.h2`
  font-family: 'Exchange Text', serif;
  font-style: italic;
  line-height: 1.5em;
  font-size: 20px;
`

const Paragraph = styled.p`
  font-family: 'Exchange Text', serif;
  line-height: 1.5em;
  font-size: 20px;
  margin-top: 1em;
`


export default JournalBlockRenderer