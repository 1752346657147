import React from 'react'

export type LanguageActionType = 'setLanguage'

interface LanguageAction {
  type: LanguageActionType
  payload?: {
    language?: Language
  }
}

type Dispatch = (action: LanguageAction) => void
interface State {
  language: Language
}
type LanguageProviderProps = {
  children: React.ReactNode
}
type Language = 'en' | 'ru' | 'pt'

const initialState: State = {
  language: 'en',
}
const LanguageContext = React.createContext<State | undefined>(initialState)
const LanguageDispatchContext = React.createContext<Dispatch | undefined>(undefined)

function LanguageReducer(state: State, action: LanguageAction): State {
  switch (action.type) {
    case 'setLanguage': {
      return (!action.payload?.language) ? state : {...state, language: action.payload.language }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function LanguageProvider({children}: LanguageProviderProps) {
  const [state, dispatch] = React.useReducer(LanguageReducer, initialState)
  return (
    <LanguageContext.Provider value={state}>
      <LanguageDispatchContext.Provider value={dispatch}>
        {children}
      </LanguageDispatchContext.Provider>
    </LanguageContext.Provider>
  )
}

function useLanguage() {
  const context = React.useContext(LanguageContext)
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider')
  }
  return context
}

function useLanguageDispatch() {
  const context = React.useContext(LanguageDispatchContext)
  if (context === undefined) {
    throw new Error('useLanguageDispatch must be used within a LanguageProvider')
  }
  return context
}

export { LanguageProvider, useLanguage, useLanguageDispatch, Language }