import React from "react"
import styled from 'styled-components'
import { SlideshowButton } from '@components'
import { GridItem, GridItemWrapper } from '@components/grid'
import { GridItemCaptionProps, GridItemImageProps, GridItemVideoProps } from '@types'
import { useWindowSize } from '@utils'

interface GridItemStripProps {
  className?: string
  _key?: string
  _type?: 'gridItemStrip'
  stripItems?: Array<GridItemImageProps | GridItemVideoProps | GridItemCaptionProps>
  children?: React.ReactNode
}

const GridItemStrip = ({ 
  stripItems,
  className,
  children,
}: GridItemStripProps): React.ReactElement => {
  const { width } = useWindowSize()

  const [gridColumnWidth, setGridColumnWidth] = React.useState<number>(1)
  React.useEffect(() => {
    setTimeout(() => setGridColumnWidth(document.getElementById('gridColumnWidth')?.offsetWidth ?? 0), 50)
  }, [width])

  const [focusIndex, setFocusIndex] = React.useState<number>(0)
  const shift = (direction: 'left' | 'right') => {
    if( direction === 'left' && focusIndex > 0 ) {
      setFocusIndex(focusIndex - 1)
    } else if( direction === 'right' && stripItems && focusIndex < stripItems.length - 1 ) {
      setFocusIndex(focusIndex + 1)
    }
  }
  
  if(!stripItems) return <></>
  
  return (
    <StyledGridItemWrapper columns={6} columnStart={1} mobileColumns={4} mobileColumnStart={1}>
      <Wrapper {...{ className }}>
        <ScrollerArrow 
          active={focusIndex >= 1} 
          onClick={() => shift('left')}
          left
        >
          <SlideshowButton facesLeft />
        </ScrollerArrow>
        <ScrollerArrow 
          active={focusIndex < (stripItems.length - 1)}
          onClick={() => shift('right')}
        >
          <SlideshowButton />
        </ScrollerArrow>
        <Canvas>
          {stripItems?.map((item, i) => (
            <StyledGridItem 
              {...item} 
              _type="gridItem"
              key={i} 
              index={i}
              columns={item.columns ?? 6} 
              mobileColumns={item.mobileColumns ?? 4}
              {...{ gridColumnWidth, focusIndex }}
            />
          ))}
          {children}
          {!children && stripItems.map((item, i) => (
            <Placeholder key={i + 9999} >
              <StyledGridItem 
                {...item} 
                _type="gridItem"
                key={i} 
                columns={item.columns ?? 6} 
                mobileColumns={item.mobileColumns ?? 4}
                {...{ gridColumnWidth }}
                placeholder
              />
            </Placeholder>
          ))}
        </Canvas>
      </Wrapper>
    </StyledGridItemWrapper>
  )
}



/* The trickiest part of this whole mess is getting the canvas to be the correct height.
  The items are absolute positioned, so the canvas "wants" to have a height of 0.
  Programmatically setting the height is a fucking mess.
  So we're just jamming in some 1px wide placeholders; the tallest item will do the work.
*/
const Placeholder = styled.div`
  overflow-x: hidden;
  display: inline-block;
  width: 1px;
  opacity: 0;
`


const Canvas = styled.div`
  position: relative;
  width: 100%;
  overflow-x: hidden;
`

const StyledGridItemWrapper = styled(props => <GridItemWrapper {...props} />)`
  overflow: visible;
`

const StyledGridItem = styled(props => <GridItem {...props} />)<{ 
  gridColumnWidth: number, 
  columns: number, 
  mobileColumns: number,
  placeholder?: boolean,
  focusIndex?: number,
  index: number,
}>`
  width: ${props => (props.mobileColumns * props.gridColumnWidth) + ((props.mobileColumns - 1) * 15)}px;
  @media only screen and (min-width: 768px) {
    width: ${props => (props.columns * props.gridColumnWidth) + ((props.columns - 1) * 40)}px;
  }
  @media only screen and (min-width: 1500px) {
    width: ${props => (props.columns * props.gridColumnWidth) + ((props.columns - 1) * 48)}px;
  }
  > * {
    margin: 0 auto;
  }

  ${props => !props.placeholder && `
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    left: calc(50% - ${((props.mobileColumns * props.gridColumnWidth) + ((props.mobileColumns - 1) * 20)) / 2}px);

    transition: transform 0.45s ease-out;
    transform-origin: center center;
    
    /*
    half a screen away:                 -50vw
    width of item:                      ((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 30)) * (Math.abs(props.focusIndex - props.index)
    one deviation from index:           * (Math.abs(props.focusIndex - props.index))
    halved to bring to edge of screen:  / 2 
    margin for visible edge:            - (Math.abs(props.focusIndex - props.index) === 1 ? 60 : 0)
    */
    
    transform: translateX(
      ${props.focusIndex === props.index ? 0
        : props.index < props.focusIndex ? `calc(-50vw - ${((props.mobileColumns * props.gridColumnWidth) + ((props.mobileColumns - 1) * 15)) * (Math.abs(props.focusIndex - props.index)) / 2 - (Math.abs(props.focusIndex - props.index) === 1 ? 20 : 0)}px)`
        : `calc(50vw + ${((props.mobileColumns * props.gridColumnWidth) + ((props.mobileColumns - 1) * 15)) * (Math.abs(props.focusIndex - props.index)) / 2 - (Math.abs(props.index - props.focusIndex) === 1 ? 20 : 0)}px)`
      }
    ); /*  scale(${props.focusIndex === props.index ? 1 : 0.8}) */
    
    @media only screen and (min-width: 768px) {
      left: calc(50% - ${((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 40)) / 2}px);
      transform: translateX(
        ${props.focusIndex === props.index ? 0
          : props.index < props.focusIndex ? `calc(-50vw - ${((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 40)) * (Math.abs(props.focusIndex - props.index)) / 2 - (Math.abs(props.focusIndex - props.index) === 1 ? 45 : 0)}px)`
          : `calc(50vw + ${((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 40)) * (Math.abs(props.focusIndex - props.index)) / 2 - (Math.abs(props.focusIndex - props.index) === 1 ? 45 : 0)}px)`
        }
      );
    }
    
    @media only screen and (min-width: 1220px) {
      left: calc(50% - ${((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 40)) / 2}px);
      transform: translateX(
        ${props.focusIndex === props.index ? 0
          : props.index < props.focusIndex ? `calc(-50vw - ${((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 40)) * (Math.abs(props.focusIndex - props.index)) / 2 - (Math.abs(props.focusIndex - props.index) === 1 ? 45 : 0)}px)`
          : `calc(50vw + ${((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 40)) * (Math.abs(props.focusIndex - props.index)) / 2 - (Math.abs(props.focusIndex - props.index) === 1 ? 45 : 0)}px)`
        }
      );
    }
    
    @media only screen and (min-width: 1500px) {
      left: calc(50% - ${((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 48)) / 2}px);
      transform: translateX(
        ${props.focusIndex === props.index ? 0
          : props.index < props.focusIndex ? `calc(-50vw - ${((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 48)) * (Math.abs(props.focusIndex - props.index)) / 2 - (Math.abs(props.focusIndex - props.index) === 1 ? 80 : 0)}px)`
          : `calc(50vw + ${((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 48)) * (Math.abs(props.focusIndex - props.index)) / 2 - (Math.abs(props.index - props.focusIndex) === 1 ? 80 : 0)}px)`
        }
      );
    }

  `}
  
  /* 
    override "fade up" animation, it messes up the scroller 
    override opacity, it doesn't trigger until more than inside margin
  */
  img, video {
    transform: none !important;
    opacity: 1 !important;
  }
      
`


const Wrapper = styled.div`
  position: relative;
`


const ScrollerArrow = styled.div<{ left?: boolean, active: boolean }>`
  position: absolute;
  z-index: 100;
  ${props => props.left ? `
    left: 0px;
  ` : `
    right: 0px;
  `}
  top: 0;
  bottom: 0;
  width: 30px;
  height: 100%;
  padding: 0 20px;
  @media only screen and (min-width: 768px) {
    padding: 0 30px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 0 45px;
  }
  @media only screen and (min-width: 1200px) {
    padding: 0 60px;
  }
  transition: opacity 0.25s ease-in-out;
  opacity: ${props => props.active ? 1 : 0};
  cursor: ${props => props.active ? 'pointer' : 'inherit'};
`



export default GridItemStrip