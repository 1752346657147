import React from "react"
import { Video } from '@components'
import { GridItemWrapper } from '@components/grid'
import { GridItemVideoProps } from '@types'
import { useGridHelper } from '@utils'


const GridItemVideo = ({
  link,
  children,
  gridItemTypeSwitch,
  className,
  columns, 
  columnStart,
  mobileColumns,
  mobileColumnStart,
  anchor,
}: GridItemVideoProps): React.ReactElement => {
  const { gridHelperEnabled } = useGridHelper()
  if( !gridItemTypeSwitch.gridItemVideo ) return <></>
  const {
    gridItemVideo: {
      video,
    },
  } = gridItemTypeSwitch
  
  return (
    <GridItemWrapper {...{ className, columns, columnStart, mobileColumns, mobileColumnStart, anchor, link, gridHelperEnabled }} >
      <Video autoPlay muted loop playsInline src={video?.asset.url} />
      {children}
    </GridItemWrapper>
  )
}





export default GridItemVideo