import React from "react"
import styled from 'styled-components'
import { TransitionState } from "gatsby-plugin-transition-link"


type TransitionStatus = 'entering' | 'entered' | 'exiting' | 'exited'


interface TransitionMaskProps {
  transitionStatus: TransitionStatus
}


const TransitionMask = ({ transitionStatus } : TransitionMaskProps): React.ReactElement => {
  return (
      <Mask {...{ transitionStatus }} className={transitionStatus === 'entered' ? '' : 'transitioning'}/>
  )
}


const Mask = styled.div<TransitionMaskProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10000;
  transition: opacity 0.5s 0.1s ease-in-out, visibility 0.01s 0.51s;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0);
  &.transitioning {
    pointer-effects: none;
    opacity: 1;
    transition: opacity 0.5s 0.1s ease-in-out, visibility 0.01s 0.01s;
    visibility: visible;
  }
`


const WrappedMask = () => (
  <TransitionState>
    {({ transitionStatus }: TransitionMaskProps) => <TransitionMask {...{ transitionStatus }} /> }
  </TransitionState>
)

export default WrappedMask
