import React from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'


// TODO import { imgixPath } from '@utils'
{/* const imgixPath = (input: string) => input.replace('cdn.sanity.io/images/zchnowat/production', 'franklyn.imgix.net') */}
const imgixPath = (input: string) => input


const Picture = ({
  objectFit = 'contain',
  className = '',
  src,
  mobileSrc,
  dimensions,
  alt,
  columns,
  mobileColumns,
  children,
  ...props
}: PictureProps): React.ReactElement => {
  if(!src) return <></>
  // Set CDN src. 
  const mobileCdnSrc = (!!mobileSrc) ? imgixPath(mobileSrc) : undefined
  const cdnSrc = imgixPath(src)

  const { ref, inView } = useInView({
    threshold: .15,
    triggerOnce: true
  })
  
  const [visible, setVisible] = React.useState(false)
  React.useEffect(() => {
    if(visible) return
    if(inView) setVisible(true)
  }, [inView])
  
  // approximate width-of-layout cut by column width
  const desktopCut = (w: number) => !columns ? w : Math.floor(w * (columns / 6))

  return (
    <picture>
      {!!children && children}
      {!children && (
        <>
          <source type="image/webp" media="(min-width: 1280px)" srcSet={`${cdnSrc}?fm=webp&w=${desktopCut(1200)}, ${cdnSrc}?fm=webp&w=${desktopCut(2400)} 2x`} />
          <source type="image/webp" media="(min-width: 1024px)" srcSet={`${cdnSrc}?fm=webp&w=${desktopCut(1024)}, ${cdnSrc}?fm=webp&w=${desktopCut(2048)} 2x`} />
          <source type="image/webp" media="(min-width: 768px)"  srcSet={`${cdnSrc}?fm=webp&w=${desktopCut(768)},  ${cdnSrc}?fm=webp&w=${desktopCut(1536)} 2x`} />
        </>
      )}

      <StyledImage
        src={`${cdnSrc}?fm=jpg`}
        alt={alt}
        {...{ objectFit, visible, ref }}
        {...dimensions}
        className={`loadable ${className}`}
        {...props}
      />
    </picture>
  )
}



// Styling

interface StyledImageProps {
  visible: boolean
  objectFit: 'contain' | 'cover'
  aspectRatio: number
}

const StyledImage = styled.img.attrs(
  ({ visible }: StyledImageProps) => {
  return { style: {
    opacity: visible ? 1 : 0,
  } }}
)<StyledImageProps>`
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
  object-fit: ${props => props.objectFit};
  transition: opacity 0.5s ease-in-out, border-radius 0.25s ease-in-out;
  @media only screen and (min-width: 768px) {
    transition: opacity 0.5s ease-in-out, border-radius 0.25s ease-in-out, transform 0.25s ease-out;
  }
  @media only screen and (max-width: 767px) {
    transform: none !important;
  }
`



// Typing

type PictureProps = {
  src: string
  mobileSrc?: string
  objectFit?: 'contain' | 'cover'
  dimensions: {
    width: number
    height: number
    aspectRatio: number
  }
  className?: string
  alt?: string
  columns?: number
  mobileColumns?: number
  children?: React.ReactNode
}



export default Picture
