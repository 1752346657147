import React from "react"
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Header } from '@components'
import { SiteSettings } from '@types'
import FontFaceObserver from 'fontfaceobserver'

import "../assets/stylesheets/reset.min.css"
import "../assets/stylesheets/main.scss"


const Layout = ({ className, children }: LayoutProps): React.ReactNode => {
  const [loaded, setLoaded] = React.useState(false)
  React.useEffect(() => {
    setTimeout(() => setLoaded(true), 5)
  }, [])
  
  const graphikFontObserver = new FontFaceObserver('Graphik')
  const newSpiritFontObserver = new FontFaceObserver('new-spirit-condensed')
  Promise.all([graphikFontObserver.load(), newSpiritFontObserver.load()]).then(
    () => document.documentElement.classList.remove('fonts-loading'),
    () => console.log('Font is not available')
  )

  const data = useStaticQuery(graphql`
    query LayoutQuery {
      sanitySiteSettings {
        ...siteSettings
      }
    }
  `)

  const { 
    sanitySiteSettings: { 
      title, description, keywords, shortcutIcon, touchIcon, shareImage 
    }
  } = data
  const meta = [
    { property: "og:type", content: "website" },
    { property: "og:url", content: `${process.env.GATSBY_SITE_BASE_URL}` },
    { charSet: 'UTF-8' },
    { name: "viewport", content: "width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0" },
    { httpEquiv: "X-UA-Compatible", content: "ie=edge" },
    { name: "apple-mobile-web-app-capable", content: "yes" },

    { property: "og:title", content: title },
    { name: "twitter:title", content: title },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:site", content: "franklyn_design" },
    
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },

    { name: "keywords", content: keywords },
    
    { property: "og:image", content: `${shareImage?.asset.url}?w=640&h=640&fm=jpg&q=75` },
    { name: "twitter:image", content: shareImage?.asset.url },
  ]

  return (
    <>
      <Helmet {...{ meta, title }} >
        <link rel="stylesheet" href="/fonts/graphik/fonts.css" />
        <link rel="stylesheet" href="https://use.typekit.net/ztj7jpv.css" />
        <link rel="preload" as="font" href="/fonts/fonts/graphik/Graphik-Medium-Cy-Web.woff2" type="font/woff2" crossOrigin="anonymous" />
        <link rel="preload" as="font" href="/fonts/fonts/graphik/Graphik-Semibold-Cy-Web.woff2" type="font/woff2" crossOrigin="anonymous" />
        <link rel="preload" as="font" href="/fonts/fonts/graphik/Graphik-Regular-Cy-Web.woff2" type="font/woff2" crossOrigin="anonymous" />
        <link rel="apple-touch-icon" href={touchIcon?.asset.url} />
        <link rel="icon" type="image/png" href={shortcutIcon?.asset.url} sizes="32x32" />
      </Helmet>
      <Top id="top" />
      <Header />
      <Wrapper className={loaded ? 'loaded' : 'loading'} {...{ className }}>
        {children}
      </Wrapper>
    </>
  )
}


const Wrapper = styled.div`
  overflow: hidden;
`


const Top = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
`



interface LayoutProps extends SiteSettings {
  subtitle?: string
  preview?: boolean
  hasHero?: boolean
  className?: string
  children: React.ReactNode
}


export default Layout
