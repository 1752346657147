import React from "react"
import styled from 'styled-components'
import BlockContent from '@sanity/block-content-to-react'


const BlockSerializer = ({children, node}: { children: React.ReactNode, node: { style: string } }): JSX.Element => {
  if(!children) return <></>
  switch(node.style) {
    case 'h1' :
      return <H1>{children}</H1>
    case 'h2' :
      return <H2>{children}</H2>
    case 'h3' :
      return <H3>{children}</H3>
    case 'h4' :
      return <H4>{children}</H4>
    case 'h5' :
      return <H5>{children}</H5>
    case 'normal' :
    case 'p1' :
      return <P1>{children}</P1>
    case 'p2' :
      return <P2>{children}</P2>
    case 'p3' :
      return <P3>{children}</P3>
    case 'p4' :
      return <P4>{children}</P4>
    case 'p6' :
      return <P6>{children}</P6>
    default:
      return <P5>{children}</P5>
  }
}

const BlockRenderer = ({ children }: { children: React.ReactNode }) => {
  return (
    <StyledBlockContent
      blocks={children}
      serializers={{
        types: {
          block: (props: any) => (<BlockSerializer {...props} />),
        },
        marks: {
        },
      }}
    />
  )
}



const StyledBlockContent = styled(props => <BlockContent {...props} />)`
    > p {
      margin-bottom: 1em;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
`



export const H1 = styled.h1`
  font-family: new-spirit-condensed, serif;
  font-style: normal;
  line-height: 1.3em;
  font-size: 15vw;
  @media only screen and (min-width: 768px) {
    font-size: 7.7vw;
  }
  @media only screen and (min-width: 1220px) {
    font-size: 94px;
  }
  @media only screen and (min-width: 1500px) {
    font-size: 119px;
  }
`

export const H2 = styled.h2`
  line-height: 1.5em;
  font-weight: 500;
  font-size: 35px;
  margin-top: 2em;
  @media only screen and (min-width: 768px) {
    font-size: 3.15vw;
  }
  @media only screen and (min-width: 1220px) {
    font-size: 38px;
  }
  @media only screen and (min-width: 1500px) {
    font-size: 45.6px;
  }
`

export const H3 = styled.h3`
  line-height: 1.5em;
  font-weight: 500;
  font-size: 27px;
  @media only screen and (min-width: 768px) {
    font-size: 2.0vw;
  }
  @media only screen and (min-width: 1220px) {
    font-size: 25px;
  }
  @media only screen and (min-width: 1500px) {
    font-size: 30px;
  }
`

export const H4 = styled.h4`
  line-height: 1.3em;
  font-weight: 500;
  font-size: 22px;
  @media only screen and (min-width: 768px) {
    font-size: 1.55vw;
  }
  @media only screen and (min-width: 1220px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 1500px) {
    font-size: 24px;
  }
`

const H5 = styled.h5`
  font-style: italic;
  line-height: 1.5em;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 1em;
  @media only screen and (min-width: 768px) {
    font-size: 1.55vw;
  }
  @media only screen and (min-width: 1220px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 1500px) {
    font-size: 24px;
  }
`

const P1 = styled.p`
  line-height: 1.3em;
  font-weight: 400;
  font-size: 22px;
  margin-top: 1em;
  @media only screen and (min-width: 768px) {
    font-size: 3.15vw;
  }
  @media only screen and (min-width: 1220px) {
    font-size: 32px;
  }
  @media only screen and (min-width: 1500px) {
    font-size: 38px;
  }
`

const P2 = styled.p`
  line-height: 1.5em;
  font-weight: 400;
  font-size: 18px;
  @media only screen and (min-width: 768px) {
    font-size: 2.0vw;
  }
  @media only screen and (min-width: 1220px) {
    font-size: 25px;
  }
  @media only screen and (min-width: 1500px) {
    font-size: 30px;
  }
  margin-bottom: 0;
`

const P3 = styled.p`
  line-height: 1.3em;
  font-weight: 400;
  font-size: 18px;
  @media only screen and (min-width: 768px) {
    font-size: 2.0vw;
  }
  @media only screen and (min-width: 1220px) {
    font-size: 25px;
  }
  @media only screen and (min-width: 1500px) {
    font-size: 30px;
  }
`

const P4 = styled.p`
  line-height: 1.5em;
  font-weight: 400;
  font-size: 18px;
  @media only screen and (min-width: 768px) {
    font-size: 1.55vw;
  }
  @media only screen and (min-width: 1220px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 1500px) {
    font-size: 24px;
  }
`

const P5 = styled.p`
  line-height: 1.5em;
  font-weight: 400;
  font-size: 18px;
  @media only screen and (min-width: 768px) {
    font-size: 1.55vw;
  }
  @media only screen and (min-width: 1220px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 1500px) {
    font-size: 24px;
  }
`

const P6 = styled.p`
  line-height: 1.3em;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
`

export default BlockRenderer