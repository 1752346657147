import styled from 'styled-components'

const SlideshowButton = styled.button<{ facesLeft?: boolean }>`
  position: absolute;
  z-index: 999;
  top: calc(50% - 20px);
  ${props => props.facesLeft ? 'left: -60px;' : 'right: -60px;'}
  @media only screen and (min-width: 768px) {
    
  }
  width: 40px;
  height: 40px;
  border-radius: 9999px;
  background-color: black;
  box-shadow: 1px 1px 6px rgba(255, 255, 255, 0.75);
  will-change: box-shadow;
  transition: box-shadow 0.15s ease-in-out;
  &:before {
    position: absolute;
    content: ' ';
    height: 1px;
    width: 10.5px;
    top: 20px;
    left: 15px;
    background-color: rgba(255, 255, 255, 0.75);
    ${props => props.facesLeft ? `
      transform-origin: top left;
      transform: rotateZ(45deg);
    ` : `
      transform-origin: top right;
      transform: rotateZ(45deg);
    `};
  }
  &:after {
    position: absolute;
    content: ' ';
    height: 1px;
    width: 10.5px;
    top: 20px;
    left: 15px;
    background-color: rgba(255, 255, 255, 0.75);
    ${props => props.facesLeft ? `
      transform-origin: top left;
      transform: rotateZ(-45deg);
    ` : `
      transform-origin: top right;
      transform: rotateZ(-45deg);
    `};
  }
  &:hover {
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
    &:before, &:after {
      background-color: rgba(255, 255, 255, 1);
    }
  }
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  overflow: hidden;
  text-indent: -9999px;
  cursor: pointer;
`

export default SlideshowButton