import styled from 'styled-components'

const NavButton = styled.button<{ active: boolean }>`
  position: fixed;
  z-index: 999;
  top: 25px;
  right: 25px;
  @media only screen and (min-width: 768px) {
    top: 50px;
    right: 50px;
  }
  width: 73px;
  height: 73px;
  border-radius: 9999px;
  background-color: black;
  box-shadow: 1.5px 1.5px 6px rgba(255, 255, 255, 1);
  will-change: box-shadow;
  transition: box-shadow 0.15s ease-in-out;
  &:before, &:after {
    transition: 
      background-color 0.15s ease-in-out, 
      transform 0.35s ease-in-out,
      top 0.15s ease-in-out,
      left 0.35s ease-in-out;
  }
  &:before {
    position: absolute;
    content: ' ';
    height: 1px;
    width: 30px;
    top: ${props => props.active ? 25 : 32}px;
    left: ${props => props.active ? 27 : 22}px;
    background-color: rgba(255, 255, 255, 1);
    transform-origin: top left;
    transform: ${props => props.active ? 'rotateZ(45deg)' : 'rotateZ(0deg)'};
  }
  &:after {
    position: absolute;
    content: ' ';
    height: 1px;
    width: 30px;
    top: ${props => props.active ? 46 : 42}px;
    left: ${props => props.active ? 27 : 22}px;
    background-color: rgba(255, 255, 255, 1);
    transform-origin: top left;
    transform: ${props => props.active ? 'rotateZ(-45deg)' : 'rotateZ(0deg)'};
  }
  &:hover {
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
    &:before, &:after {
      background-color: rgba(255, 255, 255, 1);
    }
  }
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  overflow: hidden;
  text-indent: -9999px;
  cursor: pointer;
  @media only screen and (min-width: 1140px) {
    display: none;
  }
`

export default NavButton