import React from "react"
import styled from 'styled-components'
import { Picture } from '@components'
import { GridItemWrapper } from '@components/grid'
import { GridItemImageProps } from '@types'
import { useGridHelper } from '@utils'


const GridItemImage = ({
  link,
  children,
  gridItemTypeSwitch,
  className,
  columns, 
  columnStart, 
  mobileColumns, 
  mobileColumnStart,
  anchor,
}: { className?: string } & GridItemImageProps): React.ReactElement => {
  const { gridHelperEnabled } = useGridHelper()
  if( !gridItemTypeSwitch.gridItemImage ) return <></>
  const {
    gridItemImage: {
      image,
      description,
    },
  } = gridItemTypeSwitch

  return (
    <GridItemWrapper {...{ className, columns, columnStart, mobileColumns, mobileColumnStart, anchor, link, gridHelperEnabled }} >
      <Picture 
        src={image?.asset.url}
        dimensions={image?.asset.metadata.dimensions}
        alt={description}
        columns={columns || 6}
      />
      {description && (
        <Description>{description}</Description>
      )}
      {children}
    </GridItemWrapper>
  )
}



const Description = styled.p`
  padding: 5px 10px 0px 10px;
  @media only screen and (min-width: 768px) {
    padding: 10px 140px 0px 70px;
  }
  margin-bottom: 1em;
`


export default GridItemImage