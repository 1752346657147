import React from "react"
import styled from 'styled-components'
import { Wrapper } from '@components/grid/GridItemWrapper'
import { ItemCaption } from '@components/grid/GridItemCaption'

const Grid = ({ children, className }: GridProps): React.ReactElement => (
  <GridWrapper {...{ className }} >
    {children}
  </GridWrapper>
)


const GridWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 15px;
  grid-row-gap: 60px;
  margin: 50px 25px 100px 25px;

  @media only screen and (min-width: 768px) {
    margin: 120px auto 120px auto;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-column-gap: 40px;
    grid-row-gap: 62px;
    max-width: 72.23vw;
  }
  @media only screen and (min-width: 1220px) {
    grid-row-gap: 72px;
    max-width: 880px;
  }
  @media only screen and (min-width: 1500px) {
    grid-column-gap: 48px;
    grid-row-gap: 82px;
    max-width: 1080px;
  }
  ${Wrapper}:first-of-type ${ItemCaption} h2 {
    margin-top: 0;
  }
`

type GridProps = {
  children: React.ReactNode
  className?: string
}

export default Grid