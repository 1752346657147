import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { LanguageSwitcher, NavButton } from "@components";
import { useLocation } from "@reach/router";
import { useLanguage, Language } from "@utils";
import Link from "gatsby-plugin-transition-link";

export interface NavigationLink {
  url: string;
  label: string;
  labelRu: string;
  labelPt: string;
}

interface ResidencyLink {
  _key: string;
  title: string;
  titlePt: string;
  titleRu: string;
  slug: {
    current: string;
  };
}

const Navigation = () => {
  const { pathname, hostname } = useLocation();

  React.useEffect(() => {
    if (hostname === "journal.landbase.pt") {
      window.location.href = "/_LandBase_Journal_01_BunkerMentality.pdf";
    }
  }, [hostname]);

  const { language } = useLanguage();
  const {
    sanitySiteSettings: { navigationLinks },
    allSanityResidency: { nodes },
  } = useStaticQuery(graphql`
    query NavigationQuery {
      sanitySiteSettings {
        navigationLinks {
          label
          labelRu
          labelPt
          url
        }
      }
      allSanityResidency {
        nodes {
          _key
          title
          titlePt
          titleRu
          slug {
            current
          }
        }
      }
    }
  `);

  const [active, setActive] = React.useState<boolean>(false);
  React.useEffect(() => {
    setActive(false);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [pathname]);

  return (
    <>
      <LanguageSwitcher navActive={active} />
      <NavButton onClick={() => setActive(!active)} {...{ active }}>
        Menu
      </NavButton>
      <Wrapper {...{ active }}>
        <ul className="switchable">
          {navigationLinks.map((link: NavigationLink, i: number) => (
            <li key={`navLink_${i}`}>
              <NavLink {...{ link, pathname, language, setActive, active }}>
                {language === "en"
                  ? link.label
                  : language === "ru"
                  ? link.labelRu
                  : link.labelPt}
              </NavLink>
            </li>
          ))}
        </ul>
      </Wrapper>
    </>
  );
};

interface NavLinkProps {
  language: Language;
  link: NavigationLink;
  pathname: string;
  setActive: (i: boolean) => void;
}

const NavLink = ({ language, link, pathname, setActive }: NavLinkProps) => {
  const copy =
    language === "en"
      ? link.label
      : language === "ru"
      ? link.labelRu
      : link.labelPt;
  return link.url.indexOf("#") < 0 || pathname !== "/" ? (
    <StyledLink to={link.url} href={link.url} $active={pathname === link.url}>
      {copy}
    </StyledLink>
  ) : (
    <StyledAnchor
      href={link.url}
      $active={pathname === link.url}
      onClick={() => setActive(false)}
    >
      {copy}
    </StyledAnchor>
  );
};

const Wrapper = styled.nav<{ active: boolean }>`
  position: fixed;
  z-index: 105;
  right: 0;
  top: 0;
  @media only screen and (max-width: 1139px) {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    line-height: 2.5em;
    background-color: black;
    visibility: hidden;
    touch-events: none;
    opacity: 0;
    transform: scale(1.05);
    transition: visibility 0.01s 0.35s linear, opacity 0.3s 0s ease-in-out,
      transform 0.3s 0s ease-in-out;
    will-change: opacity, transform;
    ${(props) =>
      props.active &&
      `
      visibility: visible;
      touch-events: all;
      transform: scale(1);
      opacity: 1;
      transition: 
        visibility 0.01s 0s linear, 
        opacity 0.3s 0.1s ease-in-out, 
        transform 0.3s 0.1s ease-in-out;
    `}
  }
  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 150px;
    padding-left: 30px;
  }
  @media only screen and (min-width: 1140px) {
    top: 120px;
    right: 2vw;
    line-height: 1.5em;
    font-weight: 500;
    font-size: 1.55vw;
    display: block;
    font-size: 20px;
  }
  @media only screen and (min-width: 1220px) {
    right: 4vw;
  }
  @media only screen and (min-width: 1500px) {
    top: 120px;
    right: 90px;
  }
`;

// ${props => props.$active ? '#a7733c' : '#ffffff'};
const StyledLink = styled((props) => <Link {...props} />)<{ $active: boolean }>`
  text-decoration: none;
  color: #ffffff;
  &:hover {
    color: #f3d8a8;
  }
  transition: color 0.15s ease-in-out;
`;

const StyledAnchor = styled.a<{ $active: boolean }>`
  text-decoration: none;
  color: ${(props) => (props.$active ? "#a7733c" : "#ffffff")};
  &:hover {
    color: #f3d8a8;
  }
  transition: color 0.15s ease-in-out;
`;

const IndentedStyledLink = styled((props) => <StyledLink {...props} />)`
  padding-left: 25px;
  text-transform: uppercase;
  color: #a7733c;
`;

export default Navigation;
