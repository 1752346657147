import React from 'react'
import styled from 'styled-components'
import { Anchor, LinkMask } from '@components'

interface GridItemWrapperProps {
  fullBleed?: boolean
  columns?: number
  columnStart?: number
  mobileColumns?: number
  mobileColumnStart?: number
  anchor?: string
  link?: string
  children: React.ReactNode
  topLine?: boolean
  gridHelperEnabled?: boolean
}

export const Wrapper = styled.div<GridItemWrapperProps>`
  box-sizing: border-box;
  position: relative;
  grid-column-start: ${props => props.mobileColumnStart || 'auto'};
  grid-column-end: span ${props => props.mobileColumns || 4};
  ${props => props.topLine && `
    border-top: 1px solid white;
    padding-top: 1em;
  `}

  @media only screen and (min-width: 768px) {
    grid-column-start: ${props => props.columnStart || 'auto'};
    grid-column-end: span ${props => props.columns || 6};
  }
  
  align-self: start;
  
  ${props => props.gridHelperEnabled && `outline: 1px dashed red;`}
`

const GridItemWrapper = ({ anchor, link, children, ...props}: GridItemWrapperProps) => (
  <Wrapper {...props} >
    {link && <LinkMask {...{ link }} />}
    {children}
    {anchor && <Anchor id={anchor} />}
  </Wrapper>
)

export default GridItemWrapper