import React from "react"
import styled from 'styled-components'
import { useLanguage, useLanguageDispatch, Language } from '@utils'

const LanguageSwitcher = ({ navActive }: { navActive: boolean }) => {
  const { language } = useLanguage()
  const dispatch = useLanguageDispatch()
  
  const setLanguage = (language: Language) => {
    document?.getElementById('body')?.classList.add('hiddenText')
    setTimeout(() => {
      dispatch({ type: 'setLanguage', payload: { language } })
      document?.getElementById('body')?.classList.remove('hiddenText')    
    }, 1000)
  }
  
  return (
    <Wrapper {...{ navActive }}>
      <Option active={language === 'en'} onClick={() => setLanguage('en')} >En</Option>
      <Div>/</Div>
      <Option active={language === 'ru'} onClick={() => setLanguage('ru')} >Ru</Option>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ navActive: boolean }>`
  position: fixed;
  display: block;
  z-index: 110;
  @media only screen and (max-width: 767px) {
    font-size: 30px;
    top: 30px;
    left: 30px;
    ${props => props.navActive ? `
      visibility: visible;
      opacity: 1;
      touch-events: all;
      transition: opacity 0.15s 0.3s ease-in-out, visibility: 0s .2s linear;
    ` : `
      visibility: hidden;
      opacity: 0;
      touch-events: none;
      transition: opacity 0.15s 0.3s ease-in-out, visibility: 0s 0s linear;
    `};
  }  
  @media only screen and (min-width: 768px) {
    bottom: 36px;
    left: 36px;
    font-size: 16px;
  }
`

const Div = styled.span`
  display: inline-block;
  padding: 0 0.25em;
`

const Option = styled.span<{ active: boolean }>`
  ${props => props.active && `font-weight: 600;`}
  cursor: pointer;
`

export default LanguageSwitcher